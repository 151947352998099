<template>
    <sm-report :reportName="reportName" :breadcrumbs="breadcrumbs" />
  </template>
  
  <script>
  import SmReport from '@/components/views/SmReport.vue';
  export default {
    name: 'MeterRecheckCallsReport',
  
    components: {
      SmReport,
    },
  
    data() {
      return {
        reportName: 'MeterRecheckCallsReport',
        breadcrumbs: [
          {
            text: 'Администрирование',
            route: { name: 'AdministrationMain' },
          },
          {
            text: 'Результаты ответов на звонки о необходимости поверить ПУ',
          },
        ],
      };
    },
  };
  </script>
  